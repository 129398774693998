import React, { Fragment } from "react"
import Layout from "../components/layout"
import Ads from "../components/ads"
import PostItem from "./post"

const Archive = ({ posts, type }: any) => {
  return (
    <Layout>
      <main className="py-24px">
        <div className="container narrow">
          <Ads name="home_inbody1" />
          <div className="py-24px">
            { posts.map((post: any, i: number) => (
              <Fragment key={post.id}>
                { (i!==0 && i%3==0) &&
                  <div className="mb-24px">
                    <Ads name="home_inbody2" />
                  </div>
                }
                <PostItem post={post} type={type} />
              </Fragment>
            )) }
          </div>
          <Ads name="home_inbody3" />
        </div>
      </main>
    </Layout>
  )
}

export default Archive
